import React, { useEffect } from "react";

import {
  currentCountryVar,
  currentUserVar,
  UserPoolClient,
  useReactiveVar,
  useQuery,
} from "@apollo";
import { SCREEN_GET_WALLET_DATA } from "@queries";

// Misc Imports
import { ExchangeRate, Theme } from "@app-types";
import { useTranslation } from "@i18n";
import { Wallet as WalletModel } from "@models";
import { makeStyles } from "@styles";
import { navigate } from "@utils";

// Component imports
import { Grid, Typography } from "@material";
import { WalletActivity, WalletDisplay, WalletSkeleton } from "@components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1400,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 4,
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  header: {
    fontSize: 42,
    fontWeight: 800,
    color: "black",
    marginBottom: 16,
  },
  subHeader: {
    color: "#393939",
    fontWeight: 400,
    fontSize: 20,
    marginBottom: 21,
  },
  displaysContainer: {
    marginTop: 21,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: 630,
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  transactionHeader: {
    fontWeight: 800,
    fontSize: 24,
    color: "black",
  },
  loadingSpinnerContainer: {
    height: 400,
  },
  displaySticky: {
    position: "sticky",
  },
  displayContainer: {
    [theme.breakpoints.up("sm")]: {
      top: 88,
      marginRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      top: 56,
      marginBottom: 16,
      marginRight: 0,
    },
  },
}));

type ScreenData = {
  wallet: WalletModel;
  usdRate: ExchangeRate;
};

export default function Wallet() {
  const classes: any = useStyles({});
  const { t } = useTranslation("common");

  const country = useReactiveVar(currentCountryVar);
  const user = useReactiveVar(currentUserVar);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!user) navigate("/sign-in", { replace: true });
  }, []);

  const { data, loading } = useQuery<ScreenData>(SCREEN_GET_WALLET_DATA, {
    client: UserPoolClient,
    fetchPolicy: "cache-only",
    variables: { id: user?.id, currency: country?.currencyCode },
  });

  if (loading) return <WalletSkeleton />;

  const { wallet, usdRate } = data || {};

  return !user ? null : (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.header}>
        {t("wallet")}
      </Typography>
      <Grid
        container
        item
        direction="row"
        alignItems="flex-start"
        justify="space-between"
        lg={12}
        className={classes.displaysContainer}
      >
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          className={`${classes.displayContainer}
                    ${
                      wallet?.transactions?.length > 3 && classes.displaySticky
                    }`}
        >
          {data && (
            <WalletDisplay
              wallet={wallet}
              country={country}
              usdRate={usdRate}
            />
          )}
        </Grid>
        <Grid
          container
          item
          justify="flex-end"
          alignItems="center"
          xs={12}
          md={7}
          lg={8}
        >
          {data && <WalletActivity transactions={wallet.transactions} />}
        </Grid>
      </Grid>
    </div>
  );
}
